import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		boxShadow: '0px 2px 1px -1px #E9EAED, 0px 1px 1px 0px#E9EAED, 0px 1px 3px 0px #E9EAED',
		minHeight: 72,
		[theme.breakpoints.down('sm')]: {
			minHeight: 50,
			maxWidth: "100%"
		},
		
	},
	toolbar:{
		height:72,
		padding: '0 68px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: 50,
			padding: 0,
			maxWidth: "100%"


		},
		[theme.breakpoints.between(1024, 1440)]: {
			padding: '0 35px'
		},
	},
	container:{
		[theme.breakpoints.down('sm')]:{
			justifyContent: 'center',
			alignItems:'center',
			maxWidth: "100%"

		}
	},
	buttonsContainer:{
		gap: 35,
		width: '100%',
		[theme.breakpoints.down(1024)]:{
			display: 'none',
		}
	},
	logo:{
		width: 125,
		height: 125,
		[theme.breakpoints.down('sm')]:{
			width: 80,
			height: 20,
		}
	},
	menuButtonContainer:{
		paddingLeft: 20,

	},
	menuIcon:{
		position: "absolute",
		top: 7,
		left: 20,
		"& svg":{
			fontSize: 30,
			height: 30,
		}
	},
	logoContainer:{
		[theme.breakpoints.down(1023)]:{
			display: 'flex',
			justifyContent: 'center',
			maxWidth: '100%',
			flexBasis: '100%'
		}
	}
}))