import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		width: "80%",
		[theme.breakpoints.between('sm', 'md')]: {
			width: "35%",
		}

	},
	container: {

	},
	headerContainer: {
		padding: '22px 16px',
		paddingBottom: 0
	},
	logo: {
		width: 72,
		height: 24,

	},
	closeIcon: {
		"& svg": {
			fontSize: 24,
			height: 24,
		}
	},
	menuContainer: {
		marginTop: 50,
	},
	listItem: {
		padding: '14px 16px',
		"&:hover": {
			background: '#E5F4EC'
		}
	},
	subMenuActive: {
		background: '#E5F4EC'
	},
	subMenu: {
		paddingTop: 0,

	},
	subMenuItem: {
		padding: '8px 32px',
		"&:hover": {
			background: '#E5F4EC'
		}
	},
	anchor: {
		textDecoration: "none",
		"&:visited": {
			color: "#000",
		},
		"&:active": {
			color: "#000",
		}
	},
	linkActive: {
		borderLeft: `4px solid ${theme.palette.primary.main}`
	}
}))