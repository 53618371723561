import { Divider, Grid, Typography } from '@material-ui/core'
import { EmailOutlined as EmailIcon, PhoneAndroidOutlined as PhoneIcon, PinDropOutlined as PinIcon } from '@material-ui/icons'
import React from 'react'
import { Button, Link } from '../../components'
import { useStyles } from "./Footer.styles"
import { navigate } from 'gatsby'
import clsx from 'clsx'

export function Footer() {
	const classes = useStyles()
	return (
		<Grid component="footer" container className={classes.root}>
			<div className={classes.orgInfoContainer}>
				<Grid item className={classes.order1}>
					<Link to="/">
						<img
							className={classes.logo}
							src="/assets/images/Logo.svg"
							alt="Logo de Kredi"
							width={125} height={31}
						/>

					</Link>
				</Grid>
				<Grid item className={classes.order3}>
					<Typography
						className={classes.label}
					>
						<EmailIcon className={classes.icon} />
						hola@kredi.mx
					</Typography>
				</Grid>
				<Grid item className={classes.order4}>
					<Typography
						className={classes.label}
					>
						<PhoneIcon className={classes.icon} />
						81 3231 2889
					</Typography>
				</Grid>
				<Grid item className={classes.order5}>
					<Typography
						className={classes.label}
					>
						<PinIcon className={classes.icon} />
						Monterrey, N.L.
					</Typography>
				</Grid>
				<Grid
					item
					container
					direction="row"
					className={clsx(classes.socialLinkContainer, classes.order2)}
				>
					<Grid item>
						<a target={"_blank"} href="https://www.facebook.com/kredi.mx">
							<div className={classes.socialLink} >
								<img width={16} height={16} src="/assets/images/facebook_icon.svg" alt="Logo de Facebook" />
							</div>
						</a>
					</Grid>
					<Grid item>
						<a target={"_blank"} href="https://www.linkedin.com/company/kredimx/">
							<div className={classes.socialLink} >
								<img width={14} height={14} src="/assets/images/linkendin_icon.svg" alt="Logo de Linkedin" />
							</div>
						</a>
					</Grid>
					<Grid item>
						<a target={"_blank"} href="https://twitter.com/kredimx">
							<div className={classes.socialLink}>
								<img width={14} height={14} src="/assets/images/twitter_icon.svg" alt="Logo de Twitter" />
							</div>
						</a>
					</Grid>
				</Grid>
			</div>

			<Grid
				item
				container
				direction="column"
				md={3} sm={6}
				className={classes.column}

			>
				<Grid item>
					<Typography color="primary" className={classes.columnTitle}> Clientes </Typography>
				</Grid>
				<Grid item>
					<a className={classes.anchor} href={`${process.env.GATSBY_KREDI_APP_BASE_URL}`}>
						<Typography> Solicitar </Typography>
					</a>
				</Grid>
				<Grid item>
					<a className={classes.anchor} href={`${process.env.GATSBY_KREDI_APP_BASE_URL}/magic_link`}>
						<Typography> Inicia sesión </Typography>
					</a>
				</Grid>
			</Grid>
			<Grid
				item
				container
				direction="column"
				md={3} sm={6}
				className={classes.column}
			>
				<Grid item>
					<Typography color="primary" className={classes.columnTitle}> Nosotros </Typography>
				</Grid>
				<Grid item>
					<Typography>
						<Link to="/creditos">
							Créditos
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<Typography>
						<Link to="/como-funciona">
							¿Cómo funciona?
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<Typography>
						<Link to="/sobre-nosotros">
							Nuestra historia
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<a target={"_blank"} className={classes.anchor} href="https://www.careers-page.com/kredi">
						<Typography>
							Vacantes
						</Typography>
					</a>
				</Grid>
				{/* <Grid item>
						<Typography> 
							Blog 
						</Typography>
					</Grid> */}
			</Grid>
			<Grid
				item
				container
				direction="column"
				md={3}
				className={clsx(classes.column, classes.columnWithRegulations)}

			>
				<Grid item>
					<Typography color="primary" className={classes.columnTitle}> Legal </Typography>
				</Grid>
				<Grid item>
					<Typography>
						<Link to="/terminos-y-condiciones">
							Términos y condiciones
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<Typography>
						<Link to="/aviso-de-privacidad">
							Aviso de privacidad
						</Link>
					</Typography>
				</Grid>
				<Grid
					container
					className={classes.regulations}
				>
					<Typography className={classes.regulationsTitle}>Nuestras autoridades:</Typography>
					<Grid container direction="row" alignItems="center" className={classes.imagesContainer} >
						<Grid item>
							<img
								className={classes.cnbv}
								src="/assets/images/CNBV.svg"
								alt="Logo de la Comisión Nacional Bancaria y de Valores"
							/>

						</Grid>
						<Grid item>
							<img
								className={classes.condusef}
								src="/assets/images/condusef.svg"
								alt="Logo de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros"
							/>

						</Grid>
						<Grid item>
							<img
								className={classes.buro}
								src="/assets/images/buro.svg"
								alt="Logo de Buró de Entidades Financieras"
							/>


						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				className={classes.regulationsMobile}
			>
				<Typography className={classes.regulationsTitle}>Nuestras autoridades:</Typography>
				<Grid container direction="row" alignItems="center" className={classes.imagesContainer} >
					<Grid item>
						<img
							className={classes.cnbv}
							src="/assets/images/CNBV.svg"
							alt="Logo de la Comisión Nacional Bancaria y de Valores"
						/>

					</Grid>
					<Grid item>
						<img
							className={classes.condusef}
							src="/assets/images/condusef.svg"
							alt="Logo de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros"
						/>

					</Grid>
					<Grid item>
						<img
							className={classes.buro}
							src="/assets/images/buro.svg"
							alt="Logo de Buró de Entidades Financieras"
						/>


					</Grid>
				</Grid>
			</Grid>

			<Grid
				item
				container
				className={classes.copy}
			>
				<Typography className={classes.text} style={{ marginBottom: 4 }}>
					© 2022 FINANCIERA INTELIGENTE, S.A. de C.V., S.O.F.O.M. E.N.R - Todos los derechos reservados
				</Typography>
				<Typography className={classes.text}>
					<strong>CAT Promedio del 10.8% sin IVA</strong> calculado al 28 de Enero del 2022. Está basado valor
					de vivienda con valor de 1 millón de pesos, tasa de interés promedio ponderado anual fija del 8.50%,
					para un plazo a 20 años, con 80% de financiamiento, una comisión por apertura del 1.0% financiada y
					representa el costo anual total del financiamiento expresado en términos porcentuales anuales incluyendo
					la totalidad de los costos promedio garantizados (hipotecario). Incluyendo seguros de vida y daños,
					comisión por apertura, por administración e intereses, excluyendo los impuestos y derechos federales
					y locales, así como, los costos correspondientes a trámites y servicios prestados por terceros. Para
					fines informativos y de comparación exclusivamente.
				</Typography>
				<Typography className={classes.text} style={{ marginTop: 16 }}>
					1 Amigo o familiar, si es como coacreditado, aval, obligado solidario
				</Typography>
			</Grid>
		</Grid>
	)
}