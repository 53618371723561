import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useStyles } from './Header.styles';
import { Grid, Hidden, MenuItem } from '@material-ui/core';
import { Button, Menu, Link, IconButton } from '../../components';
import { ExpandLess, ExpandMore, Menu as MenuIcon } from '@material-ui/icons';
import { useState } from 'react';
import { Sidebar } from './Sidebar';

export function Header({ location }: any) {
	const classes = useStyles()
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [openSidebar, setOpenSidebar] = useState(false)
	return (
		<>
			<AppBar
				color="inherit"
				position="fixed"
				elevation={1}
				classes={{
					root: classes.root
				}}>
				<Toolbar className={classes.toolbar}>
					<Grid
						container
						direction="row"
						alignContent="center"
						justifyContent="space-between"
						className={classes.container}>
						<Hidden mdUp>
							<Grid item md={6} sm={3} className={classes.menuButtonContainer} >
								<IconButton
									onClick={() => setOpenSidebar(true)}
									color="primary"
									className={classes.menuIcon}>
									<MenuIcon />
								</IconButton>
							</Grid>

						</Hidden>
						<Grid className={classes.logoContainer} item md={6} sm={9} >
							<Link to="/">
								<img className={classes.logo} src="/assets/images/Logo.svg" alt="Logo de Kredi" />
							</Link>
						</Grid>
						<Grid
							item
							md={6}
							container
							alignContent="center"
							justifyContent="flex-end"
							className={classes.buttonsContainer}>
							<Button to={`${process.env.GATSBY_KREDI_APP_BASE_URL}/magic_link`} useNativeAnchor>
								Iniciar sesión
							</Button>
							<Button
								onClick={(e) => setAnchorEl(e.currentTarget)}>
								Nosotros {Boolean(anchorEl) ? <ExpandLess /> : <ExpandMore />}
							</Button>
							<Button
								color="primary"
								variant="contained"
								target="_self"
								to={`${process.env.GATSBY_KREDI_APP_BASE_URL}`}
								useNativeAnchor
							>
								Solicitar
							</Button>
						</Grid>

					</Grid>
				</Toolbar>
			</AppBar>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}>
				<Link to="/creditos"><MenuItem>Créditos </MenuItem></Link>
				<Link to="/como-funciona"><MenuItem> ¿Cómo funciona?</MenuItem></Link>
				<Link to="/sobre-nosotros"><MenuItem>Nuestra historia</MenuItem></Link>
			</Menu>
			<Sidebar location={location} open={openSidebar} onClose={() => { setOpenSidebar(false) }} />
		</>
	)
}