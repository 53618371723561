import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	content: {
		marginTop: 72,
		maxWidth: "100vw",
		overflow: 'hidden',
		[theme.breakpoints.down(1024)]: {
			marginTop: 50,

		}
	}
}))