import React, { useEffect } from 'react'
import { ReactElement } from 'react';
import Cookies from 'universal-cookie';
import { Theme } from '../assets/theme';
import { SEO, SEOProps } from './SEO'
import { Header } from './Header'
import { useStyles } from './Layout.styles';
import { Footer } from './Footer';

const cookies = new Cookies();

function setQueryParams() {
	if (window.location.search) {
		let today = new Date();
		let date = new Date();
		date.setTime(today.getTime() + 3600000);
		const dateUTCString = date.toUTCString();
		const kredi_data = window.location.search.replace('?', '')
		document.cookie = `kredi_data=${kredi_data};path=/;domain=kredi.mx;expires=${dateUTCString}`;
		// document.cookie = `kredi_data=${window.location.search};path=/;expires=${date}`;
	}
}

function identityCustomer() {
	const customerId = cookies.get('customer_id');
	if (customerId) {
		//@ts-ignore
		analytics.identify(customerId, {
			id: customerId,
		});
	}
}

interface LayoutProps extends SEOProps {
	children: ReactElement[] | ReactElement
	location: any
}

export function Layout({ children, location, ...rest }: LayoutProps) {

	useEffect(() => {
		setQueryParams();
		identityCustomer();

		//@ts-ignore
		window.dataLayer = window.dataLayer || []
		//@ts-ignore
		window.dataLayer.push({ event: 'optimize.activate' })
	}, []);

	const classes = useStyles()

	return (
		<Theme>
			<SEO location={location} {...rest} />
			<Header location={location} />
			<div className={classes.content}>
				{children}
			</div>
			<Footer />
		</Theme>
	)
}

