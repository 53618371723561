import { makeStyles } from "@material-ui/core";
import { backgroundColor } from "config/site";

export const useStyles = makeStyles((theme) => ({
	root: {
		height: 400,
		width: '100%',
		backgroundImage: 'url("/assets/images/footer_image_bg.svg")',
		backgroundRepeat: 'no-repeat',
		backgroundPositionX: 'right',
		padding: '0 68px',
		backgroundSize: '205.05px 330px',

		[theme.breakpoints.down('md')]: {
			padding: "0 16px",
			height: '100%',
			backgroundPositionY: 'calc(100% - 56px)',
			backgroundSize: '148.05px 252px',
			backgroundImage: "unset",

		},
		//display: 'grid',
		//gridTemplateColumns: '300px 1fr 1fr'

	},
	logo: {
		width: 125,
		marginBottom: 10,
		[theme.breakpoints.down('md')]: {
			marginBottom: 0,
		}
	},
	column: {
		marginTop: '76px',
		gap: 16,
		maxWidth: "22%",

		"& p": {
			display: 'inline',
			padding: 8,
			whiteSpace: 'nowrap'

		},
		"& p:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.04)",
			borderRadius: 55,
		},
		[theme.breakpoints.down('md')]: {
			marginTop: 24,
			width: "33%",
			maxWidth: "33%",
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: 24,
			width: "50%",
			maxWidth: "50%",
		}
	},
	columnTitle: {
		fontSize: 22,
		fontWeight: 'bold',
		lineHeight: '30px',
		backgroundColor: "unset !important",
		borderRadius: 0,
	},
	icon: {
		fontSize: '14px',
		fill: theme.palette.primary.main
	},
	label: {
		fontSize: '17px',
		display: 'flex',
		alignItems: 'baseline',
		gap: 5,

	},
	socialLinkContainer: {
		marginTop: 14,
		gap: 16,
		[theme.breakpoints.down(1024)]: {
			width: '100%',
			justifyContent: 'flex-end',
			marginTop: 0,

		}
	},
	socialLink: {
		background: '#E8F7F0',
		borderRadius: '50%',
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 4,
		boxSizing: 'border-box',
		[theme.breakpoints.down('md')]: {
			width: 32,
			height: 32,
		}
	},

	copy: {
		padding: "16px 0",
		borderTop: '1px solid #CFD5E2',
		marginTop: 16,
		"& p": {
			fontSize: 12,
			lineHeight: "16px",

			textAlign: 'left'
		},
		justifyContent: 'flex-start',
		[theme.breakpoints.down('sm')]: {
			marginTop: 50
		},
	},
	text: {
		fontSize: 10,
		lineHeight: "16px",
	},

	order1: {
		gridColumn: 1,
		gridRow: 1,
		[theme.breakpoints.down('sm')]: {
			// order: 1
			gridColumn: 1,
			gridRow: 1
		}
	},
	order2: {
		gridColumn: 1,
		gridRow: 5,
		[theme.breakpoints.down('xs')]: {
			// order: 2,
			gridColumn: 2,
			gridRow: 1
		},
		[theme.breakpoints.between('sm', 1023)]: {
			gridColumn: 3,
			gridRow: 1

		},


	},
	order3: {
		gridColumn: 1,
		gridRow: 2,
		[theme.breakpoints.down('sm')]: {
			gridColumn: 1,
			gridRow: 2
		}
	},
	order4: {
		gridColumn: 1,
		gridRow: 4,
		[theme.breakpoints.down('xs')]: {

			gridColumn: 2,
			gridRow: 2,
			justifySelf: 'center'
		},
		[theme.breakpoints.between('sm', 1023)]: {
			width: '100%',
			justifyContent: 'flex-start',
			gridColumn: 2,
			gridRow: 2,

		},
		[theme.breakpoints.between(1024, 1440)]: {


		}
	},
	order5: {
		gridColumn: 1,
		gridRow: 3,
		[theme.breakpoints.down('md')]: {
			//order: 5,
			gridColumn: 1,
			gridRow: 3
		},
		[theme.breakpoints.between('sm', 1023)]: {
			gridColumn: 3,
			gridRow: 2

		},
	},


	orgInfoContainer: {
		display: 'grid',
		gridTemplateColumns: '100%',
		gridTemplateRows: "72px repeat(3, 0.25fr) 38px",
		marginTop: 60,
		marginRight: 129,
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			marginTop: 40,
			marginRight: 0,
			width: '100%',
			gridTemplateColumns: '50% 50%',
			gridTemplateRows: "69px 41px 1fr",
			paddingBottom: 24,
			borderBottom: " 1px solid #CFD5E2"


		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			gridTemplateColumns: '33% 33% 33%',
			gridTemplateRows: "69px 41px",
			marginRight: 0,
			width: "100%",

		},
		//1024 - 1440
		[theme.breakpoints.between(1024, 1440)]: {
			gridTemplateColumns: '100%',
			gridTemplateRows: "72px repeat(3, 39px) 38px",
			marginTop: 25,
			marginRight: 70,
			marginLeft: 25,
			marginBottom: 50,
			width: '15%'

		},
		[theme.breakpoints.down(1023)]: {
			borderBottom: " 1px solid #CFD5E2"
		}

	},

	anchor: {
		textDecoration: "none",
		"&:visited": {
			color: "#000",
		},
		"&:active": {
			color: "#000",
		}
	},

	regulations: {
		//width: 500,
		marginBottom: 16,
		[theme.breakpoints.down(1024)]: {
			display: "none"
		}
	},

	regulationsMobile: {
		padding: "0 8px",
		marginTop: 24,
		[theme.breakpoints.up(1024)]: {
			display: 'none'
		}
	},

	regulationsTitle: {
		fontSize: 14,
		fontWeight: "bold",
		"&:hover": {
			backgroundColor: "unset !important",
			borderRadius: "unset !important",
		},
		[theme.breakpoints.down(1024)]: {
			fontSize: 18,
			lineHeight: '20px'
		}
	},
	imagesContainer: {
		marginTop: 10,
		gap: 20,
		[theme.breakpoints.down(1024)]: {
			marginTop: 12,
			gap: 40,
			//justifyContent: 'space-between',
		}

	},

	cnbv: {
		width: 120,
		height: 51,

	},
	condusef: {
		width: 90,
		height: 51,

	},
	buro: {
		width: 52,
		height: 52,

	},


	columnsContainer: {
		display: 'grid',
		gridTemplateColumns: "repeat(3, minmax(255px, max-content))",
		gap: 40,
	},
	columnWithRegulations: {
		[theme.breakpoints.up(1024)]: {
			width: "100%",
			maxWidth: "100%",
		}
	}

}))