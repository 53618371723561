import { Collapse, Drawer, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import { Close, ExpandLess, ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import React, { useState } from "react";
import { IconButton, Link } from "../../../components";
import { useStyles } from "./Sidebar.styles";

interface SidebarProps {
	open: boolean,
	onClose: () => void;
	location: any
}

export function Sidebar({ open, onClose, location }: SidebarProps) {
	const classes = useStyles()
	const [openCollapse, setOpenCollapse] = useState(false)
	function closeMenu() {
		onClose()
	}
	return (
		<Drawer
			classes={{
				paper: classes.root
			}}
			open={open}
			onClose={onClose}>
			<Grid container className={classes.container}>
				<Grid container className={classes.headerContainer} justifyContent="space-between" alignItems="center">
					<Grid item>
						<img className={classes.logo} src="/assets/images/Logo.svg" alt="Logo de Kredi" />
					</Grid>
					<Grid item>
						<IconButton onClick={onClose} className={classes.closeIcon}>
							<Close />
						</IconButton>
					</Grid>
				</Grid>
				<Grid container direction="column" className={classes.menuContainer}>
					<List>
						<a className={classes.anchor} href={`${process.env.GATSBY_KREDI_APP_BASE_URL}/magic_link`}>
							<ListItem className={classes.listItem}>
								<Typography>Iniciar sesión</Typography>
							</ListItem>
						</a>
						<ListItem
							onClick={() => setOpenCollapse(!openCollapse)}
							className={clsx(classes.listItem, {
								[classes.linkActive]:
									location.pathname.includes("/como-funciona") ||
									location.pathname.includes("/sobre-nosotros") ||
									location.pathname.includes("/creditos"),
								[classes.subMenuActive]: openCollapse
							})}>
							<ListItemText primary="Nosotros" /> {!openCollapse ? <ExpandMore /> : <ExpandLess />}

						</ListItem>
						<Collapse in={openCollapse}>
							<List className={classes.subMenu}>
								<Link to="/creditos">
									<ListItem
										onClick={closeMenu}
										className={clsx(classes.subMenuItem,
											{ [classes.linkActive]: location.pathname === "/creditos" })
										}>
										<Typography>Créditos</Typography>
									</ListItem>
								</Link>
								<Link to="/como-funciona">
									<ListItem
										onClick={closeMenu}

										className={clsx(classes.subMenuItem,
											{ [classes.linkActive]: location.pathname.includes("/como-funciona") })
										}>
										<Typography>¿Cómo funciona?</Typography>
									</ListItem>
								</Link>
								<Link to="/sobre-nosotros">
									<ListItem
										onClick={closeMenu}
										className={clsx(classes.subMenuItem,
											{ [classes.linkActive]: location.pathname === "/sobre-nosotros" })}>
										<Typography>Nuestra historia</Typography>
									</ListItem>
								</Link>
							</List>
						</Collapse>
						<a className={classes.anchor} href={`${process.env.GATSBY_KREDI_APP_BASE_URL}`}>
							<ListItem className={classes.listItem}>
								<Typography>Solicitar</Typography>
							</ListItem>
						</a>
					</List>
				</Grid>
			</Grid>
		</Drawer>
	)
}

